<template>
  <CDropdown color="light">
    <template #toggler-content>
      <CIcon name="cil-tag" />
      <CBadge class="ml-1" :color="selectedLabel.color">{{ selectedLabel.label }}</CBadge>
    </template>

    <CDropdownItem v-for="(el, index) in $globalVar.emailLabels" :key="index" @click="() => emailLabels = el.value">
      <CBadge :color="el.color">{{ el.label }}</CBadge>
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: "LabelDropdown",
  props: {
    labels: {
      type: Array,
      default: () => ["waiting"],
    },
  },
  computed: {
    emailLabels: {
      get() {
        return this.labels;
      },
      set(newValue) {
        if (newValue === this.labels.join()) return;

        this.$emit("update:labels", [newValue]);
        this.$emit("change");
      },
    },
    selectedLabel() {
      return this.$globalVar.emailLabels.find((el) => el.value === this.emailLabels[0]);
    },
  }
};
</script>
