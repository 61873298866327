<template>
  <div class="toolbar p-3">
    <div class="btn-group mr-2">
      <CButton color="dark" :to="{ name: folder.capitalize() }" v-c-tooltip="{ content: `Back to ${folder.capitalize()}`}">
        <CIcon name="cil-arrow-left" />
        <span class="ml-1 small">Back to {{ folder.capitalize() }}</span>
      </CButton>

      <CButton
        color="light"
        @click="toggleStarred()"
        v-c-tooltip="{
          content: email.is_starred ? 'Starred' : 'Not starred',
        }"
        ><CIcon name="cil-star" :class="{ 'text-warning': email.is_starred }"
      /></CButton>

      <CButton
        color="light"
        @click="moveToTrash"
        v-c-tooltip="{
          content: 'Delete',
        }"
        ><CIcon name="cil-trash"
      /></CButton>
    </div>

    <div class="btn-group">
      <!-- <LabelDropdown v-if="email.labels" :labels.sync="email.labels" @change="changeLabel" :class="{ 'disable-item': !email.is_my_task }"></LabelDropdown> -->
      <LabelDropdown :labels.sync="email.labels" @change="changeLabel"></LabelDropdown>

      <CButton color="light" @click="showLabelActivityLogs">
        <CIcon name="cil-fingerprint" /><span class="ml-1 small">Label History</span>
      </CButton>
    </div>

    <LabelActivityLogs :messageID="messageID" />
  </div>
</template>

<script>
import LabelActivityLogs from "./LabelActivityLogs";
import LabelDropdown from "./LabelDropdown";
export default {
  name: "MailToolbar",
  components: {
    LabelActivityLogs,
    LabelDropdown,
  },
  props: {
    email: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      messageID: null,
    };
  },

  computed: {
    folder() {
      return this.$route.params.folder;
    },
  },

  methods: {
    toggleStarred() {
      this.email.is_starred = !this.email.is_starred;
      this.$axios
        .put(
          this.$backend.CRM_EMAILS.UPDATE_EMAIL.replace("{id}", this.email.id),
          { starred: this.email.is_starred }
        )
        .catch(() => {
          this.email.is_starred = !this.email.is_starred;
        });
    },
    moveToTrash() {
      this.$axios
        .put(
          this.$backend.CRM_EMAILS.UPDATE_EMAIL.replace("{id}", this.email.id),
          { "move-to-trash": true }
        )
        .then(() => {
          this.$router.push({ name: this.folder.capitalize() });
        });
    },
    changeLabel() {
      this.$axios
        .put(
          this.$backend.CRM_EMAILS.UPDATE_EMAIL.replace("{id}", this.email.id),
          { labels: this.email.labels }
        );
    },

    showLabelActivityLogs() {
      this.messageID = this.email.message_id;
      this.$store.commit('toggle', 'labelActivityLogsAsideShow');
    }
  },
};
</script>
