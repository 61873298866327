<template>
  <CSidebar
    aside
    :show="$store.state.labelActivityLogsAsideShow"
    @update:show="(val) => $store.commit('set', ['labelActivityLogsAsideShow', val])"
    colorScheme="light"
    overlaid
    size="lg"
  >
    <CSidebarClose @click.native="$store.commit('toggle', 'labelActivityLogsAsideShow')" />

    <!-- Error Alert -->
    <CListGroup class="list-group-accent mt-5" v-show='showErrorAlert'>
      <CListGroupItem class="list-group-item-accent-secondary bg-light text-danger text-center font-weight-bold text-uppercase">
        Error
      </CListGroupItem>

      <CListGroupItem href="#" class="list-group-item-accent-danger list-group-item-divider">
        <div class="c-avatar float-left mr-2">
          <CIcon name="cil-bug" size="xl" class="c-avatar-img text-danger"/>
        </div>
        <div>{{ errorAlertMessage }}</div>
      </CListGroupItem>
    </CListGroup>
    <!-- / Error Alert -->

    <CListGroup class="scrollable list-group-accent mt-5" v-show='!showErrorAlert'>
      <CListGroupItem class="list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase">
        Label History
      </CListGroupItem>

      <template v-for="activity in logs">
        <CListGroupItem class="list-group-item-divider" :class="`list-group-item-accent-${activity.new_label?.color}`">
          <div>{{ activity?.description }}</div>
          <div class="small text-muted mt-1">
            <CIcon name="cil-tag" class="mr-2" />
            {{ activity.old_label?.label }}
            <CIcon name="cil-arrow-right" class="mx-2"/>
            {{ activity.new_label?.label }}
          </div>
          <div class="small text-muted mt-1">
            <CIcon name="cil-calendar" class="mr-2" />
            {{ moment(activity.created_at).calendar() }}
          </div>
        </CListGroupItem>
      </template>
    </CListGroup>

    <CElementCover :opacity="0.4" v-show="loading"/>

  </CSidebar>
</template>

<script>
export default {
  name: "LabelActivityLogs",
  props: ['messageID'],
  data() {
    return {
      loading: false,
      logs: [],

      errorAlertMessage: '',
      showErrorAlert: false,
    };
  },
  watch: {
    reloadParams() {
      this.$store.state.labelActivityLogsAsideShow && this.getLabelActivityLogs();
    },
  },
  computed: {
    reloadParams() {
      return [this.$store.state.labelActivityLogsAsideShow];
    },
  },

  methods: {
    getLabelActivityLogs() {
      this.logs = [];
      this.loading = true;
      this.$axios
        .get(
          this.$backend.ACTIVITIES.GET_BY_PROPERTY, {
            params: {
              message_id: this.messageID,
            }
          }
        )
        .then((response) => {
          this.logs = response.data.map((el) => {
            return {
              description: el.description,
              old_label: this.getLabel(el.properties.old_labels),
              new_label: this.getLabel(el.properties.new_labels),
              created_at: el.created_at,
              // properties: JSON.parse(el.properties),
            };
          })
        })
        .catch((error) => {
          this.errorAlertMessage = error.response.data.message
          this.showErrorAlert = true
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getLabel(values) {
      return values ? this.$globalVar.emailLabels.find((el) => el.value === values[0]) : null;
    },
  }
};
</script>

<style scoped>
.scrollable {
  overflow-y: auto;
  height: 100%;
}
</style>
