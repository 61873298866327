<template>
  <div v-if="email">
    <MailToolbar v-bind="{ email }" />
    <div class="c-message p-3 flex-column">
      <div class="c-message-details">
        <div class="c-message-headers">
          <div class="c-message-headers-subject mb-3">
            <h3 class="msg-subject">{{ email.subject }}</h3>
            <CBadge v-if="email.is_my_task" class="ml-3 badge-task"><CIcon name="cil-bolt" /> Your Task</CBadge>
            <CBadge v-if="email.is_outgoing" color="light" class="ml-3" ><span class="text-info"><CIcon name="cil-send" /> Outgoing Email</span></CBadge>
          </div>

          <div class="d-flex">
            <CImg
              :src="email.sender?.image_icon || email.sender?.gravatar"
              class="c-avatar-img mr-3"
              style="width: 40px; height: 40px"
              placeholderColor="lightgray"
            />

            <div>
              <div class="c-message-headers-from">
                <div>
                  <strong>{{ email.sender?.first_name }}</strong>
                  <span class="text-muted">&lt;{{ email.from }}&gt;</span>
                </div>

                <div>
                  <CBadge class="badge-msg-from">TO</CBadge>
                  <span class="ml-1 text-muted">&lt;{{ email.reply_to?.toString() }}&gt;</span>
                </div>
              </div>

              <div class="c-message-headers-from" v-if="email.cc && email.cc.length > 0">
                <CBadge class="badge-msg-from">CC</CBadge>
                <span class="text-muted ml-1">{{ email.cc.join(', ') }}</span>
              </div>

              <div class="c-message-headers-from" v-if="email.restaurant">
                <CIcon name="cil-restaurant" />
                <CLink class="font-weight-bold" target="_blank" :to="{name: 'View Restaurant',params: { id: email.restaurant_id }}">
                  {{ email.restaurant.restaurant_name }}
                </CLink>
              </div>
            </div>
          </div>

          <div class="c-message-headers-date">
            {{ moment(email.created_at).calendar() }}
          </div>
        </div>

        <div class="mt-3 c-message-body" v-html="email.reply"></div>

        <hr />

        <div class="card">
          <ul class="list-group list-group-flush">
            <li class="list-group-item" v-for="(email, index) in email.childs" :set="lastMessage = email">
              <div class="c-message-details p-0" :id="email.message_id">
                <div class="c-message-headers">

                  <div class="d-flex">
                    <CImg
                      :src="email.sender?.image_icon || email.sender?.gravatar"
                      class="c-avatar-img mr-3"
                      style="width: 40px; height: 40px"
                      placeholderColor="lightgray"
                    />

                    <div class="c-message-headers-from">
                      <div>
                        <strong>{{ email.sender?.first_name }}</strong>
                        <span class="text-muted">&lt;{{ email.from }}&gt;</span>
                        <CBadge v-if="email.is_outgoing" color="light" class="ml-3" ><span class="text-info"><CIcon name="cil-send" /> Outgoing Email</span></CBadge>
                      </div>

                      <div>
                        <CBadge class="badge-msg-from">TO</CBadge>
                        <span class="ml-1 text-muted">&lt;{{ email.reply_to?.toString() }}&gt;</span>
                      </div>
                    </div>
                  </div>

                  <div class="c-message-headers-date">
                    {{ moment(email.created_at).calendar() }}
                  </div>
                </div>
                <div class="mt-3 c-message-body" v-html="email.reply"></div>
              </div>
            </li>
          </ul>
        </div>

        <template v-if="showReply">
          <div class="form-group reply" ref="reply">
            <quill-editor
              :options="{ placeholder: 'Click here to reply', theme: 'snow' }"
              v-model="reply"
            />
          </div>

          <div class="form-group">
            <CButton class="mr-1" color="info" @click="replyEmail"
              ><CIcon name="cil-send" /> Send</CButton
            >
          </div>
        </template>
      </div>
    </div>

    <CElementCover :opacity="1" v-show="loading">
      <CSpinner size="5xl" grow color="primary" />
    </CElementCover>
  </div>
  <div v-else class="p-3">
    <CSpinner v-if="loading" />
    <div v-else>
      <CIcon name="cil-arrow-left" /> <CLink :to="{ name: 'Inbox' }">Back to Inbox</CLink>
      <span class="mx-3">|</span>
      <CLink :to="{ name: 'Task' }">Task List</CLink>

      <div class="alert alert-danger d-flex align-items-center mt-4">
        <CIcon name="cil-warning" class="mr-1" size="lg" />
        <strong class="mx-2">Warning!</strong> All messages related to this thread have been deleted.
      </div>
    </div>
  </div>
</template>

<script>
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import MailToolbar from "./MailToolbar";

export default {
  name: "Message",
  components: {
    MailToolbar,
    quillEditor,
  },
  data() {
    return {
      email: null,
      reply: "",
      loading: false,
      lastMessage: null,
    };
  },
  computed: {
    messageID() {
      return this.$route.params.id;
    },
    folder() {
      return this.$route.params.folder;
    },
    userID() {
      return this.$route.query.user;
    },
    showReply() {
      // return this.email && this.email.folders && !this.email.folders.includes("sent") && !this.email.folders.includes("trash");
      return true;
    },
  },

  async mounted() {
    await Promise.all([this.getMessage(this.messageID)]);

    // Scroll to last message
    this.lastMessage && this.$smoothScroll({
      scrollTo: document.getElementById(this.lastMessage.message_id),
      duration: 1000,
      offset: -120,
    })
  },

  methods: {
    async getMessage(id) {
      this.loading = true;
      await this.$axios
        .get(this.$backend.CRM_EMAILS.SHOW.replace("{id}", id), {
          params: {
            "user-id": this.userID,
          },
        })
        .then((response) => {
          this.email = response.data;
        })
        .catch((error) => {
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async replyEmail() {
      this.loading = true;
      await this.$axios
        .post(this.$backend.CRM_EMAILS.SEND_EMAIL, {
          parent_id: this.lastMessage ? this.lastMessage.id : this.email.id,
          to: { email: this.email.reply_to[0] },
          subject: `Re: ${this.email.subject}`,
          reply: this.reply,
          labels: this.email.labels,
        })
        .then((response) => {
          this.$toast.success(`Email sent!`);
          this.getMessage(this.messageID);
          this.reply = "";
        })
        .catch((error) => {
          this.$toast.error(`${error.response.data.message}`);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.reply {
  min-height: 320px;
  margin-bottom: 0;
  margin-top: 1.5rem;
}
.reply .ql-editor {
  min-height: 240px;
}

.c-email-app .c-message-details {
  border: none;
}

.msg-subject {
  display: inline-block;
  border-bottom: 5px solid #f9dd94;
}
</style>
